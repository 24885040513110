import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import icon1 from "../img/web/footer/icon1.png";
import icon2 from "../img/web/footer/icon2.png";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <InfoWrapper>
          <div>
            <Link
                to={
                  "https://npercent.s3.ap-northeast-2.amazonaws.com/service/index.html"
                }
            >
              이용약관
            </Link>
            <Link
                to={
                  "https://npercent.s3.ap-northeast-2.amazonaws.com/agreement/index.html"
                }
            >
              개인정보처리방침
            </Link>
            <Link
                to={
                  "https://npercent.s3.ap-northeast-2.amazonaws.com/location/index.html"
                }
            >
              위치기반서비스이용약관
            </Link>
          </div>

          <div>
            <div>주식회사 에이비엠랩(ABM Lab)</div>
            <div>대표자 : 황의윤</div>
            <div>사업자등록번호 : 820-87-03095</div>
            <div> 통신판매업신고번호 : 2023-대구중구-1021</div>
          </div>

          <p>
            서울 : (06059) 서울특별시 강남구 선릉로133길 4, 아이큐박스
            3층,에이비엠랩
          </p>
          <p>대구 : (41934) 대구광역시 중구 약령길 83, 3층 326호</p>
          <p>&copy; Copyright ABMLab All Rights Reserved @2025 ABMLab</p>
        </InfoWrapper>

        <IconWrapper>
          <Link
              to="https://www.instagram.com/npercent.official/"
            target="_blank"
          >
            <img src={icon1} alt="icon" width="40px" />
          </Link>

          <Link to="https://www.youtube.com/@npercent_official" target="_blank">
            <img src={icon2} alt="icon" width="40px" />
          </Link>
        </IconWrapper>
      </FooterContentWrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2c2f33;

  @media screen and (max-width: 1220px) {
    padding: 0;
    width: 100%;
  }
`;

const FooterContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 30px 0;

  @media screen and (max-width: 1220px) {
    margin: 0;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  color: #d1d4d6;
  text-align: left;

  > div {
    > a {
      text-decoration: none;
      color: white;
    }
  }

  > div:first-child {
    font-size: 14px;
    padding-bottom: 16px;
    font-weight: 700;

    > a + a {
      margin-left: 16px;
    }
  }

  > div:nth-child(2) {
    color: #a3a8ac;
    font-size: 14px;
    font-weight: 700;
  }

  > p {
    color: #a3a8ac;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 1220px) {
    padding: 0 16px;

    > div:first-child {
      font-size: 10px;
    }

    > div:nth-child(2) {
      font-size: 10px;
      text-align: left;
      > div + div {
        margin-left: 0;
      }
    }

    > p {
      font-size: 10px;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > a {
    background-color: #2c2f33;
    cursor: pointer;
    border: 0;
    margin-right: 10px;
  }
`;

export default Footer;
